<template>
  <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-selection">
      <v-row justify="center">
        <v-col class="pl-0 pr-0 text-left " cols="12">
          <h1 class="selection__title">Atracción y selección</h1>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <template v-for="data in listActionSelection">
              <v-col
                v-if="data.permisson"
                class="pl-0 pr-6 selection__col-card"
                :key="data.id"
                cols="2"
              >
                <v-card outlined elevation="0" class="selection__card">
                  <v-card-title>
                    <v-layout justify-center>
                      <img width="100" :src="data.icon" />
                    </v-layout>
                  </v-card-title>
                  <v-card-text style="height: 80px" class="text-left">
                    <p class="title-selection">{{ data.title }}</p>
                    <p class="text-selection">{{ data.description }}</p>
                  </v-card-text>
                  <v-card-actions class="pt-0 pa-xl-1 pt-xl-5 mt-0 mb-3 mt-3">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        @click="go(data)"
                        block
                        rounded
                        style="color: #fff"
                        :style="{
                          'background-color': hover ? '#324CA1' : '#466BE3',
                        }"
                        class="pa-xl-0 mt-10"
                      >
                        {{ data.btnName }}
                      </v-btn>
                    </v-hover>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>



    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      origin: window.location.origin,
      loadingAll: false,
      infoToken: {
        document: "",
      },
      name: "",
      apps: [],
      listActionSelection: [
        {
          mobileVersion: true,
          icon: origin + "/icon/note-favorite.svg",
          description:
            "Registre aquí las vacantes disponibles para trabaja con nosotros.",
          title: "Registrar vacante",
          redirect: "selectionVacancies",
          externalRedirect: false,
          permisson: true, //Esteban
          btnName: "Vacantes",
          id: 1,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/selection-register.svg",
          description:
            "Lista de candidatos registrados desde colabora con nosotros.",
          title: "Lista de candidatos",
          redirect: "candidateList",
          externalRedirect: false,
          permisson: true, //Esteban
          btnName: "Ver lista",
          id: 2,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/selection-register.svg",
          description:
            "Lista de vacantes y candidatos.",
          title: "Lista de candidatos Gerencia",
          redirect: "vacanciesList",
          externalRedirect: false,
          permisson: false, //Esteba
          btnName: "Ver lista",
          id: 4,
        },
      ],
      name: "",
      token: "",
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
    };
  },
  computed: {
    getAllApps() {
      let allApps = this.$store.getters.getApps;
      if (allApps.message) {
        this.getApps(allApps.data);
      } else {
        this.loadingAll = true;
      }
    },
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getApps(allApps) {
      if (allApps && allApps.length !== 0) {
        let end = allApps.length - 1;
        for (let i = 0; i < allApps.length; i++) {
          if (
            allApps[i].es_un_modulo == "true" &&
              (allApps[i]._ID == "461" ||
              allApps[i]._ID == "619")
          ) {
            for (let j = 0; j < this.listActionSelection.length; j++) {
              if (allApps[i]._ID == "619") {
                if (this.listActionSelection[j].id == 4) {
                  this.listActionSelection[j].permisson = true;
                }
              }
              else if (allApps[i]._ID == "461") {
                if (this.listActionSelection[j].id == 4) {
                  this.listActionSelection[j].permisson = true;
                }
              }
            }
          }
          if (i == end) {
            this.loadingAll = false;
          }
        }
      }
    },
    go(data) {
      if (data.externalRedirect) {
        let newURL = document.createElement("a");
        newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
        newURL.href = data.redirect;
        newURL.target = "_blank";
        document.body.appendChild(newURL);
        newURL.click();
      } else {
        if (data.redirect === "commissionsSign") {
        } else {
          this.$router.push({ name: data.redirect });
        }
      }
    },
  },
  created() {
    this.getToken();
  },
  watch: {
    getAllApps: function (newCount, oldCount) {
      console.log("");
    },
  },
};
</script>
<style lang="less" scoped>
@import "./../../assets/css/main.less";

.mb-xl-selection {
  display: block;
}
.mb-sm-selection {
  display: none;
}

.title-selection {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #353535;
}
.text-selection {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #626262;
  text-align: center !important;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.requests-card-selection {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  border-radius: 10px !important;
  border: 1px solid #466be3 !important;
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
}

.request-project-sign {
  color: #466be3;
  font-family: "RobotoMedium" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
  font-size: 2rem !important;
}

.request-txt-project-sign {
  font-family: "RobotoRegular";
  color: #898989 !important;
  font-weight: 300;
  font-size: 1rem;
}

.selection__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.selection__col-card {
  max-width: 280px !important;
}

.selection__card {
  border-radius: 15px !important;
  width: 280px;
}

.selection__card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .title-selection {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-selection {
    display: none;
  }
  .mb-sm-selection {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .title-selection {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-selection {
    display: none;
  }
  .mb-sm-selection {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .title-selection {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-selection {
    display: none;
  }
  .mb-sm-selection {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .title-selection {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-selection {
    display: none;
  }
  .mb-sm-selection {
    display: block;
    text-align: initial;
  }
}
</style>
